<template>
    <div class="app-body">
        <bread-crumb></bread-crumb>
        <el-card class="main">
            <div>
                <h4>变更流程</h4>
                <div class="w75p mga">
                    <el-steps :active="0">
                        <!-- wait / process / finish / error / success -->
                        <el-step title="合同变更" status="finish" icon="el-icon-time"></el-step>
                        <el-step title="待乙方确认" icon="el-icon-question"></el-step>
                        <el-step title="加盟商生效" icon="el-icon-question"></el-step>
                    </el-steps>
                </div>
            </div>
            <div class="pdb30">
                <h4>加盟商信息</h4>
                <el-form :model="projectForm" ref="projectForm" :rules="projectRules" label-width="120px" label-position="left">
                    <el-form-item label="加盟类型" prop="companyId">
                        <span>{{ detailData.companyType | initDic(agencyTypeDic) }}</span>
                    </el-form-item>
                    <el-row :gutter="72">
                        <!-- 甲方 -->
                        <el-col :span="12">
                            <el-form-item label="甲方" prop="companyId">
                                <span>{{ detailData.parentCompanyName || '-' }}</span>
                            </el-form-item>
                            <el-form-item label="加盟商等级" prop="companyId">
                                <span v-if="detailData.companyType == 1">{{ detailData.agencyLevel+1 }}级加盟商</span>
                                <span v-if="detailData.companyType == 2">服务商</span>
                            </el-form-item>
                            <el-form-item label="最大可分配营收比例(%)" prop="companyId">
                                <span>{{ maxRate }}%</span>
                            </el-form-item>
                            <el-form-item :label="projectForm.agencyType == 1?'甲方营收比例(%)':'甲方服务费(%)'" prop="companyId">
                                <span>{{ maxRate - (detailData.rate/100) }}%</span>
                            </el-form-item>
                            <el-form-item :label="projectForm.agencyType == 1?'变更后甲方营收比例(%)':'变更后甲方服务费(%)'" prop="rateA">
                                <el-input 
                                    style="width:140px" 
                                    maxlength="6" 
                                    v-model="projectForm.rateA" 
                                    @input="projectForm.rateA=projectForm.rateA.replace(/^\.+|[^\d]+/g,'')"></el-input>
                            </el-form-item>
                            <el-form-item label="是否需要确认" prop="companyId">
                                <el-radio-group v-model="projectForm.needConfirm">
                                    <el-radio :label="1">是</el-radio>
                                    <el-radio :label="0">否</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <!-- 乙方 -->
                        <el-col :span="12">
                            <el-form-item label="乙方" prop="agencyCompanyId">
                                <span class="a-ml-20">{{ detailData.companyName || '-' }}</span>
                            </el-form-item>
                            <el-form-item label="乙方营收比例(%)" prop="companyId">
                                <span class="a-ml-20">{{ detailData.rate/100 }}%</span>
                            </el-form-item>
                            <el-form-item label="变更后乙方营收比例(%)" prop="companyId">
                                <span class="a-ml-20" v-if="projectForm.rate || projectForm.rate==='0' || projectForm.rate===0">{{ projectForm.rate }}%</span>
                                <span class="a-ml-20" v-else>-</span>
                            </el-form-item>
                            <el-form-item label="加盟商合同" prop="companyId">
                                <le-upload-file v-model="detailData.contractFile" ref="pic" @input="uploadFlie"></le-upload-file>
                            </el-form-item>
                            <el-form-item label="客服电话" prop="phone">
                                <el-input 
                                    class="a-ml-20"
                                    style="width:240px" 
                                    maxlength="15" 
                                    v-model="projectForm.phone" 
                                    @input="projectForm.phone=projectForm.phone.replace(/^\.+|[^\d]+/g,'')"></el-input>
                            </el-form-item>
                            <el-form-item label="基础收款商户" prop="baseCompany" label-width='140px'>
                                <el-select v-model="projectForm.baseCompany" @change="baseCompanyChange" style="width:240px" >
                                    <el-option v-for="item in baseCompanyDic"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <div style="height:100px"></div>
            
        </el-card>
        <div class="a-line-t-e0 footerBox">
            <el-button type="primary" class="a-ml-24 a-mt-15" @click="submit">立即提交</el-button>
            <el-button class="a-ml-24 a-mt-15" @click="cancel" style="margin-left:24px">
                &nbsp;&nbsp;&nbsp;取消&nbsp;&nbsp;&nbsp;</el-button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        var checkRateA = (rule, value, callback) => {
            if(!this.projectForm.rateA){
                callback(new Error('请输入甲方营收比例'))
            }else if(this.projectForm.rateA > this.maxRate){
                callback(new Error('甲方营收比例不得超过最大可分配比例'))
            }else{
                callback()
            }
        };
        return {
            companyId: '',//
            maxRate: 0,
            detailData: '',
            projectForm: {
                currentCompanyId:'',
                needConfirm: 0,//是否确认
                agencyId: '',//乙方合同id
                rate: 0,//营收比例
                rateA: '',
                contractFile: '',//合同文件
                phone: '',
                baseCompany: ''
            },
            projectRules:{
                rateA: [{ validator: checkRateA, trigger: 'change' }],//营收比例
                baseCompany: [{required: true, message:'请选择基础收款商户', trigger: 'change'}],//乙方
            },
            maxRate: 0,
            maxRateOrigin: 0,
            agencyTypeDic: [],
            baseCompanyDic: [{
                label: '上海玖电科技有限公司',
                value: 1
            },{
                label: '深圳市源助力科技有限公司',
                value: 2
            }],//基础收款商户
        }
    },
    created() {
        this.companyId = this.$route.query.code
        this.projectForm.currentCompanyId=this.companyId
        this.$getDic('companyType','select').then(res=>{ this.agencyTypeDic = res; })
        this.getSigningAgencyDetail()
    },
    watch:{
        'projectForm.rateA':{
            handler (val) {
                if(val>this.maxRate){
                    this.projectForm.rate = ''
                }else if(val){
                    this.projectForm.rate = this.maxRate - val
                } else{
                    this.projectForm.rate = ''
                }
            }
        }
    },
    methods: {
        agencyTypeChange () {
            if(this.projectForm.agencyType == 1){
                this.maxRate = this.maxRateOrigin
            }else{
                this.maxRate = 100
            }
        },
        submit() {
            this.$refs['projectForm'].validate((valid) => {
                if (valid) {
                    let rate=this.projectForm['rate']*100
                    this.$Axios._post({
                        url: this.$Config.apiUrl.changeAgency,
                        params: { 
                            ...this.projectForm,
                            rate:parseInt(this.projectForm.rate)*100,
                            agencyId:parseInt(this.projectForm.agencyId),
                        }

                    }).then(async res => {
                        if (res.result.code == 0) {
                            if(this.projectForm.phone){
                                await this.addUpdateCustomerPhone()
                            }
                            this.$message.success('变更合同成功!')
                            setTimeout(() => {
                                this.$router.push({
                                    path: '/franchisee/franchisee-contract?code='+this.companyId
                                })
                            }, 1500)
                        } else {
                            this.$message.error(res.result.message)
                        }
                    })
                }
            })
            
        },
        //获取合同详情
        getSigningAgencyDetail() {
            this.$Axios._get({
                url: this.$Config.apiUrl.getSigningAgencyDetail,
                params: {
                    agencyCompanyId: parseInt(this.companyId)
                }
            }).then(res => {
                if (res.result.code == 0) {
                    if (res.result.data) {
                        this.detailData = res.result.data
                        this.projectForm.agencyId = this.detailData.id
                        this.projectForm.agencyType = this.detailData.companyType
                        this.projectForm.phone = this.detailData.phone
                        this.projectForm.baseCompany = this.detailData.baseCompany
                        this.getAgencyMaxRate(this.detailData.parentCompanyId)
                        // if(this.detailData.companyType == 1) {
                        //     this.baseCompanyDic = [{
                        //         label: '上海玖电科技有限公司',
                        //         value: 1
                        //     }]
                        // }
                    } 
                } 
            })
        },
        //获取最大营收比例
        getAgencyMaxRate(code) {
            this.$Axios._get({
                url: this.$Config.apiUrl.getAgencyMaxRate,
                params: { companyId: code }
            }).then((res) => {
                this.maxRate = res.result.data
                this.maxRateOrigin = res.result.data / 100 == 100 ? 100 : (res.result.data / 100).toFixed(2)
                this.maxRate = this.maxRateOrigin
            })
        },
        // 添加加盟商自定义客服电话
        addUpdateCustomerPhone () {
            return new Promise((resolve, reject) => {
                this.$Axios._post({
                    url: this.$Config.apiUrl.addUpdateCustomerPhone,
                    params: { 
                        companyId: this.companyId,
                        phone: this.projectForm.phone
                    }
                }).then(res => {
                    if (res.result.code != 0) {
                        this.$message.error(res.result.message)
                    }
                    resolve()
                }).catch(_=>{
                    resolve()
                })
            })
        },
        //上传文件
        uploadFlie(val) {
            this.projectForm.contractFile = val
        },
        //退出
        cancel() {
            this.$router.back()
        }
    }
}
</script>

<style lang="scss" scoped>
.main {
    height: 100%;
    overflow: auto;
}
</style>